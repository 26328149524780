import Button from "../../components/button";

import "./style.scss";
import AuthForm from "../../components/login";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleIsLecturer, logIn } from "../../redux/features/auth-slice";
import axios from "axios";
import star from "../../assets/header-right-img.png";
import logoK from "../../assets/LOGO-K.svg";

export default function Main() {
	const [isLecturerEntrance, setLecturerEntrance] = useState(false);
	const [loading, setLoading] = useState(false);
	let [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const code = searchParams.get("code");
	const toggleLecturerEntrance = () => {
		if (!code) {
			window.location.replace(
				`https://zoom.us/oauth/authorize?response_type=code&client_id=AjtOdc8jRTGYUib_oIoWvQ&redirect_uri=${process.env.REACT_APP_NGROK_URL}`
			);
		} else {
			setLecturerEntrance(!isLecturerEntrance);
		}
	};

	useEffect(() => {
		if (code) {
			dispatch(toggleIsLecturer(true));
			setLecturerEntrance(true);
		} else {
			setLecturerEntrance(false);
		}
	}, []);

	const authenticateUser = async (mailAdress) => {
		setLoading(true);
		await axios({
			url: `${process.env.REACT_APP_API_URL}/api/zoom-credentials`,
			method: "post",
			data: { code: code, mailAdress },
		})
			.then((response) => {
				dispatch(
					logIn({
						userName: response.data?.zoomUser?.display_name,
						mailAdress: response.data?.zoomUser?.email,
						zoomUserId: response.data?.token,
						meetingList: response.data?.meetingList,
						isLecturer: true,
					})
				);
			})
			.then(() => {
				setLoading(false);
				navigate("/lecturer-dashboard", { replace: true });
			})
			.catch((err) => {
				console.log(err, "ERROR");
				// if (err.response.data === "invalid_grant") {
				// 	window.location.replace(
				// 		`https://zoom.us/oauth/authorize?response_type=code&client_id=AjtOdc8jRTGYUib_oIoWvQ&redirect_uri=${process.env.REACT_APP_NGROK_URL}`
				// 	);
				// }
			});
	};

	return (
		<main className='main'>
			<figure className='banner-right-img left_icon img'>
				<img src={star} alt='' className='star' />
			</figure>
			<div className='logo-wrapper'>
				<img className='logo' src={logoK} alt='seenmood logo' />
			</div>
			<div className='mainWrapper'>
				{!isLecturerEntrance && (
					<div className='user-status'>
						<h1>Choose a user type.</h1>
						<div>
							<div className='center'>
								<Button clickFunc={toggleLecturerEntrance} customClass='big'>
									Teacher
								</Button>
							</div>
							<div className='center'>
								<Button
									customClass='big'
									clickFunc={() => navigate("/student-dashboard")}>
									Student
								</Button>
							</div>
						</div>
					</div>
				)}
				{isLecturerEntrance && (
					<AuthForm
						backFunc={toggleLecturerEntrance}
						submitFunc={authenticateUser}
						loading={loading}
					/>
				)}
			</div>
			<footer className='footer'>SeenMood.inc</footer>
		</main>
	);
}
