import { useEffect, useState } from "react";
import MeetingForm from "../../components/meeting-form";
import Modal from "../../components/modal";
import {
	logIn,
	logOut,
	setMeetings,
	toggleIsLecturer,
} from "../../redux/features/auth-slice";
import { toggleMeetingFormVisibility } from "../../redux/features/ui-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";
import MeetingCard from "../../components/meeting-card";
import "./style.scss";
import Button from "../../components/button";
import star from "../../assets/header-right-img.png";

const LecturerDashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userName, meetingList, zoomUserId, mailAdress } = useSelector(
		(state) => state.authReducer
	);
	const isMeetingModalVisible = useSelector(
		(state) => state.uiReducer.meetingFormVisible
	);

	const [isLogoutVisible, setIsLogoutVisible] = useState(false);
	const [meetings, setMeetingList] = useState([]);

	useEffect(() => {
		if (!userName) navigate("/login");
	}, []);

	useEffect(() => {
		if (meetingList) setMeetingList(meetingList);
	}, [meetingList]);

	const switchMeetingModal = () => dispatch(toggleMeetingFormVisibility());

	const toggleLogoutVisibility = () => setIsLogoutVisible(!isLogoutVisible);

	const listMeetings = async () => {
		await axios({
			url: `${process.env.REACT_APP_API_URL}/api/get-meetings-list`,
			method: "post",
			data: { token: zoomUserId, mailAdress },
		})
			.then((response) => {
				dispatch(setMeetings(response.data));
			})
			.catch((err) => console.log("error from axios", err));
	};

	const loggingOut = () => {
		axios({
			url: `${process.env.REACT_APP_API_URL}/api/delete-user-token`,
			method: "delete",
			data: { token: zoomUserId, mailAdress },
		})
			.then(() => dispatch(logOut()))
			.then(() => window.location.replace("/"));
	};

	return (
		<div className='dashboardMain'>
			<figure className='banner-right-img left_icon img'>
				<img src={star} alt='' className='star' />
			</figure>

			<div className='dashboardHeader'>
				<Modal
					modalTitle='Create a meeting'
					setModalVisibility={switchMeetingModal}
					isModalVisible={isMeetingModalVisible}>
					<MeetingForm />
				</Modal>

				<div className='user-profile'>
					<Button
						clickFunc={toggleLogoutVisibility}>{`welcome ${userName}`}</Button>
					{isLogoutVisible && (
						<Button customClass='absolute' clickFunc={loggingOut}>
							Log Out
						</Button>
					)}
				</div>
			</div>
			<div className='dashboardContentWrapper'>
				<h1>My Courses</h1>
				<div className='meetingList'>
					{!meetings && <Spinner />}
					{meetings?.length <= 0 ? (
						<p>No courses right now.</p>
					) : (
						meetings?.map((m) => {
							const date = new Date(m.start_time);
							const year = date.getFullYear();
							const month = date.getMonth() + 1;
							const day = date.getDate();
							const hour = date.getHours();
							const minute = date.getMinutes();
							return (
								<MeetingCard
									key={m.uuid}
									topic={m.agenda}
									date={`${day}/${month}/${year}`}
									time={`${hour}:${minute}`}
									userName={userName}
									meetingId={m.id}
									token={zoomUserId}
									relaodFunc={listMeetings}
								/>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export default LecturerDashboard;
