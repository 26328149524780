import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	classMembers: [],
	averageAttention: 0,
	averageArousal: 0,
};

export const videoCall = createSlice({
	name: "videoCall",
	initialState,
	reducers: {
		addMemberToClass: (state, action) => ({
			...state,
			classMembers: [...state.classMembers, action.payload],
		}),
		getClassList: (state, action) => {
			return {
				...state,
				classMembers: action.payload,
			};
		},
		removeMemberFromClass: (state, action) => ({
			...state,
			classMembers: state.classMembers.filter(
				(el) => el.socketId !== action.payload.socketId
			),
		}),
		setArousolValence: (state, action) => {
			const studentIndex = state.classMembers.findIndex(
				(el) => el.socketId === action.payload.socketId
			);
			const tmp = [...state.classMembers];
			tmp[studentIndex] = {
				...tmp[studentIndex],
				arousal: action.payload.arousal,
				valence: action.payload.valence,
			};

			const totalArousal = tmp.reduce(
				(accumulator, currentValue) => accumulator + currentValue.arousal,
				0
			);
			return {
				...state,
				classMembers: tmp,
				averageArousal: tmp.length > 0 ? totalArousal / tmp.length : 0,
			};
		},
		setAttention: (state, action) => {
			const studentIndex = state.classMembers.findIndex(
				(el) => el.socketId === action.payload.socketId
			);
			const tmp = [...state.classMembers];
			tmp[studentIndex] = {
				...tmp[studentIndex],
				attention: action.payload.attention,
			};
			const totalAttention = tmp.reduce(
				(accumulator, currentValue) => accumulator + currentValue.attention,
				0
			);

			return {
				...state,
				classMembers: tmp,
				averageAttention: tmp.length > 0 ? totalAttention / tmp.length : 0,
			};
		},
		setEmotions: (state, action) => {
			const studentIndex = state.classMembers.findIndex(
				(el) => el.socketId === action.payload.socketId
			);
			const tmp = [...state.classMembers];
			tmp[studentIndex] = {
				...tmp[studentIndex],
				emotions: action.payload.emotions,
			};

			return { ...state, classMembers: tmp };
		},
	},
});

export const {
	addMemberToClass,
	removeMemberFromClass,
	setArousolValence,
	setAttention,
	setEmotions,
	getClassList,
} = videoCall.actions;
export default videoCall.reducer;
