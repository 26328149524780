import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth-slice";
import uiReducer from "./features/ui-slice";
import videoCallReducer from "./features/video-call-slice";

export const store = configureStore({
	reducer: {
		authReducer,
		uiReducer,
		videoCallReducer,
	},
});
