import { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import logo from "../../assets/LOGO-K.svg";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import star from "../../assets/header-right-img.png";

function StudentDashboard() {
	const navigate = useNavigate();
	const [meetingInfo, setMeetingInfo] = useState({
		userName: "",
		password: "",
		meetingNumber: "",
	});

	return (
		<div className='student-dashboard'>
			<figure className='banner-right-img left_icon img'>
				<img src={star} alt='' className='star' />
			</figure>
			<div className='logo-wrapper'>
				<img className='logo' src={logo} alt='seenmood logo' />
			</div>
			<div className='student-form'>
				<h2>Join Meeting</h2>
				<form>
					<div>
						<input
							type='text'
							id='username'
							value={meetingInfo.userName}
							placeholder='Username'
							onChange={(e) =>
								setMeetingInfo({ ...meetingInfo, userName: e.target.value })
							}
						/>
					</div>

					<div>
						<input
							type='text'
							id='meetingNumber'
							value={meetingInfo.meetingNumber}
							placeholder='Meeting Number'
							onChange={(e) =>
								setMeetingInfo({
									...meetingInfo,
									meetingNumber: e.target.value,
								})
							}
						/>
					</div>
					<div>
						<input
							type='password'
							id='password'
							value={meetingInfo.password}
							placeholder='Meeting Password'
							onChange={(e) =>
								setMeetingInfo({ ...meetingInfo, password: e.target.value })
							}
						/>
					</div>
					<Button
						clickFunc={() =>
							navigate(
								`/student-video-call?meetingNumber=${meetingInfo.meetingNumber}&password=${meetingInfo.password}&userName=${meetingInfo.userName}&role=1`
							)
						}>
						Join Meeting
					</Button>
					<Button customClass='switch' clickFunc={() => navigate(`/`)}>
						Go Back
					</Button>
				</form>
			</div>

			<footer className='footer'>SeenMood.inc</footer>
		</div>
	);
}

export default StudentDashboard;
