import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	meetingFormVisible: false,
};

export const ui = createSlice({
	name: "ui",
	initialState,
	reducers: {
		toggleMeetingFormVisibility: (state) => ({
			...state,
			meetingFormVisible: !state.meetingFormVisible,
		}),
	},
});

export const { toggleMeetingFormVisibility } = ui.actions;
export default ui.reducer;
