import Button from "../button";
import "./style.scss";
import close from "../../assets/close.png";

export default function Modal(props) {
	const { modalTitle, children, isModalVisible, setModalVisibility } = props;
	return (
		<>
			<Button className='modal-button' clickFunc={() => setModalVisibility()}>
				{modalTitle}
			</Button>
			{isModalVisible && (
				<div className='modal-wrapper' onClick={() => setModalVisibility()}>
					<div className='modal-inner' onClick={(e) => e.stopPropagation()}>
						<div className='modal-header'>
							<h3>{modalTitle}</h3>
							<Button clickFunc={() => setModalVisibility()}><img src={close}/></Button>
						</div>
						<div className='modal-content'>{children}</div>
					</div>
				</div>
			)}
		</>
	);
}
