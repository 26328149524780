import React from "react";
import "./style.scss";

function Spinner() {
	return (
		<div className='spinner-wrapper'>
			<div className='lds-ripple '>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default Spinner;
