import { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "../modal";
import MeetingEditForm from "../meeting-edit-form";
import { deleteAMeeting } from "../../utils/apis";
import Spinner from "../spinner";
import Button from "../button";
import copy from "../../assets/copy.png";
import check from "../../assets/check.png";

function MeetingCard({
	topic,
	date,
	time,
	meetingId,
	userName,
	token,
	relaodFunc,
}) {
	const navigate = useNavigate();
	const [meetingPassword, setMeetingPassword] = useState("");
	const [isEditModalVisible, setEditModalVisibility] = useState(false);
	const [loading, setLoading] = useState(false);
	const [copiedPassword, setCopiedPassword] = useState(false);
	const [copiedMeeting, setCopiedMeeting] = useState(false);

	useEffect(() => {
		getMeetingDetail();
	}, []);

	const getMeetingDetail = () => {
		axios({
			url: `${process.env.REACT_APP_API_URL}/api/meeting-detail`,
			method: "post",
			data: { token, meetingId },
		})
			.then((response) => {
				setMeetingPassword(response.data.pstn_password);
			})
			.catch((err) => console.log("error from axios", err));
	};

	const toggleEditModalVisibility = () =>
		setEditModalVisibility(!isEditModalVisible);

	const deleteMeeting = () => {
		setLoading(true);
		deleteAMeeting(token, meetingId).finally(() => {
			relaodFunc();
			setLoading(false);
		});
	};

	const copyPasswordToClipboard = () => {
		setCopiedPassword(true);
		navigator.clipboard.writeText(meetingPassword);
		setTimeout(() => {
			setCopiedPassword(false);
			clearTimeout();
		}, 1500);
	};

	const copyMeetingNumberToClipboard = () => {
		setCopiedMeeting(true);
		navigator.clipboard.writeText(meetingId);
		setTimeout(() => {
			setCopiedMeeting(false);
			clearTimeout();
		}, 1500);
	};

	return (
		<div className='meeting-card-wrapper'>
			<div className='meeting-information'>
				<div className='meeting-general-info'>
					<span className='meeting-name'>{topic}</span>-
					<span>
						{date} - {time}
					</span>
				</div>
				<div className='meeting-subinfo'>
					<div>
						<p>Meeting Id: {meetingId}</p>{" "}
						<div
							className='visibility-button'
							onClick={copyMeetingNumberToClipboard}>
							<img src={copiedMeeting ? check : copy} alt='copy-paste' />
						</div>
					</div>
					<div>
						<p>Meeting Password: {meetingPassword}</p>
						<div
							className='visibility-button'
							onClick={copyPasswordToClipboard}>
							<img src={copiedPassword ? check : copy} alt='copy-paste' />
						</div>
					</div>
				</div>
			</div>
			<div className='meeting-actions'>
				<Button
					clickFunc={() =>
						navigate(
							`/lecturer-video-call?meetingNumber=${meetingId}&userName=${userName}&role=0`
						)
					}>
					Join
				</Button>
				<Modal
					modalTitle='Edit'
					setModalVisibility={toggleEditModalVisibility}
					isModalVisible={isEditModalVisible}>
					<MeetingEditForm
						meetingId={meetingId}
						meetingName={topic}
						meetingPassword={meetingPassword}
						toggleEditMeetingFormVisibility={toggleEditModalVisibility}
						changeMeetingDetail={getMeetingDetail}
					/>
				</Modal>
				<div>
					{loading ? (
						<Spinner />
					) : (
						<Button clickFunc={deleteMeeting} type='danger'>
							Delete
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default MeetingCard;
