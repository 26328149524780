import "./style.scss";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toggleMeetingFormVisibility } from "../../redux/features/ui-slice";
import { setMeetings } from "../../redux/features/auth-slice";
import { useDispatch } from "react-redux";
import Spinner from "../spinner";
import Button from "../button";

export default function MeetingEditForm({
	meetingId,
	meetingPassword,
	meetingName,
	toggleEditMeetingFormVisibility,
	changeMeetingDetail,
}) {
	const { zoomUserId, mailAdress } = useSelector((state) => state.authReducer);
	const [isSuccess, setIsSuccess] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [meetingInfo, setMeetingInfo] = useState({
		agenda: meetingName,
		password: meetingPassword,
		settings: {
			allow_multiple_devices: true,
			authentication_option: false,
			focus_mode: false,
			meeting_authentication: false,
			topic: "lesson",
			type: 1,
			approval_type: 0,
			waiting_room: false,
			host_video: true,
			participant_video: true,
			join_before_host: true,
			jbh_time: 0,
		},
	});
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const editedMeeting = await axios({
			url: `${process.env.REACT_APP_API_URL}/api/edit-meeting`,
			method: "post",
			data: { meetingInfo, token: zoomUserId, meetingId, mailAdress },
		});

		await axios({
			url: `${process.env.REACT_APP_API_URL}/api/get-meetings-list`,
			method: "post",
			data: { token: zoomUserId, mailAdress },
		}).then((resp) => dispatch(setMeetings(resp.data)));
		changeMeetingDetail();
		setLoading(false);
		editedMeeting.status === 200 ? setIsSuccess(true) : setIsSuccess(false);
		// zoomUserId;
		setTimeout(() => {
			setIsSuccess(undefined);
			toggleEditMeetingFormVisibility();
			clearTimeout();
		}, 1000);
	};
	return (
		<div className='meeting-form'>
			{isSuccess === undefined && (
				<form>
					<div>
						<label htmlFor='meetingName'>Meeting Name:</label>
						<input
							type='text'
							id='meetingName'
							value={meetingInfo.agenda}
							onChange={(e) =>
								setMeetingInfo({ ...meetingInfo, agenda: e.target.value })
							}
						/>
					</div>
					<div>
						<label htmlFor='password'>Meeting Password:</label>
						<input
							type='password'
							id='password'
							value={meetingInfo.password}
							onChange={(e) =>
								setMeetingInfo({ ...meetingInfo, password: e.target.value })
							}
						/>
					</div>
					<Button clickFunc={handleSubmit}>Edit Meeting</Button>
				</form>
			)}
			{isSuccess && <h1>Meeting parameters are changed successfully</h1>}
			{isSuccess === false && (
				<h1> Something went wrong, please try again later</h1>
			)}
			{loading && <Spinner />}
		</div>
	);
}
