// src/AuthForm.js
import React, { useState } from "react";
import "./style.scss";
import Button from "../button";
import visibility from "../../assets/visibility.png";
import unvisibility from "../../assets/unvisibility.png";

const AuthForm = (props) => {
	const [isLogin, setIsLogin] = useState(true);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");

	const handleSwitch = () => {
		setIsLogin(!isLogin);
		setError("");
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!isLogin && password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}
		setError("");
		if (isLogin) {
			props.submitFunc(email);
		} else {
			// Handle signup logic
			console.log("Signing up with", { email, password });
		}
	};

	const togglePasswordVisibility = () => setShowPassword(!showPassword);

	return (
		<div className='auth-container'>
			<h2>{isLogin ? "Login" : "Sign Up"}</h2>
			{error && <div className='error'>{error}</div>}
			<form className='login-form'>
				<div class='input-wrapper'>
					<input
						type='email'
						placeholder='Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</div>
				<div class='input-wrapper'>
					<input
						type={showPassword ? "text" : "password"}
						placeholder='Password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
					<div className='visibility-button' onClick={togglePasswordVisibility}>
						{password.length > 0 && (
							<img
								src={showPassword ? unvisibility : visibility}
								alt='visibility'
							/>
						)}
					</div>
				</div>

				{!isLogin && (
					<div class='input-wrapper'>
						<input
							type={showPassword ? "text" : "password"}
							placeholder='Confirm Password'
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							required
						/>
					</div>
				)}

				{props.loading ? (
					<div className='loader' />
				) : (
					<Button clickFunc={handleSubmit}>
						{isLogin ? "Login" : "Sign Up"}
					</Button>
				)}
				<Button
					type={props.loading ? "disabled" : ""}
					customClass='switch'
					clickFunc={handleSwitch}>
					Switch to {isLogin ? "Sign Up" : "Login"}
				</Button>
				{props.backFunc && (
					<Button
						type={props.loading ? "disabled" : ""}
						customClass='switch back'
						clickFunc={props.backFunc}>
						Are you a student?
					</Button>
				)}
			</form>
		</div>
	);
};

export default AuthForm;
