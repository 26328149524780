import axios from "axios";

export const authorizeUser = async () => {
	const resp = await axios.get(
		`https://zoom.us/oauth/authorize?response_type=code&client_id=AjtOdc8jRTGYUib_oIoWvQ&redirect_uri=${process.env.NGROK_URL}/lecturer-dashboard`,
		{
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}
	);
};
export const getUserCredential = async (mailAdress) => {
	const resp = await axios.get(
		`${process.env.REACT_APP_API_URL}/api/${mailAdress}/token`,
		{
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		}
	);

	return resp.data;
};

export const deleteUserSSOToken = async (token, mailAdress) => {
	const resp = await axios.delete(
		`${process.env.REACT_APP_API_KEY}/users/${mailAdress}/token`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"content-type": "application/json",
			},
			withCredentials: true,
		}
	);

	return resp.data;
};

export const deleteAMeeting = async (token, meetingId) => {
	const resp = await axios.delete(
		`${process.env.REACT_APP_API_URL}/api/delete-meeting`,
		{
			data: { meetingId, token },
		}
	);

	return resp.data;
};

export const createAMeeting = async (payload, token) => {
	const resp = await axios.post(
		`${process.env.REACT_APP_API_KEY}/users/${payload.mailAdress}/meetings`,
		payload,
		{
			params: {
				status: "active",
			},
			headers: {
				Authorization: `Bearer ${token}`,
				"content-type": "application/json",
			},
			withCredentials: true,
		}
	);
};

export const listHostMeetings = async (token, mailAdress) => {
	const resp = await axios.get(
		`${process.env.REACT_APP_API_URL}/users/${mailAdress}/meetings`,
		{
			params: {
				status: "active",
			},
			headers: {
				Authorization: `Bearer ${token}`,
				"content-type": "application/json",
			},
			withCredentials: true,
		}
	);

	return resp.data.meetings;
};
