import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LecturerDashboard from "./pages/LecturerDashboard";
import StudentDashboard from "./pages/StudentDashboard";
import Main from "./pages/Main";
import "./App.css";
import Spinner from "./components/spinner";
import LandingPage from "./pages/LandingPage";
const StudentVideoCall = React.lazy(() => import("./pages/StudentVideoCall"));
const LecturerVideoCall = React.lazy(() => import("./pages/LecturerVideoCall"));

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<LandingPage />} />
					<Route path='/login' element={<Main />} />
					<Route path='/lecturer-dashboard' element={<LecturerDashboard />} />
					<Route path='/student-dashboard' element={<StudentDashboard />} />
					<Route
						path='/student-video-call'
						element={
							<React.Suspense fallback={<Spinner />}>
								<StudentVideoCall />
							</React.Suspense>
						}
					/>
					<Route
						path='/lecturer-video-call'
						element={
							<React.Suspense fallback={<Spinner />}>
								<LecturerVideoCall />
							</React.Suspense>
						}
					/>
					<Route path='*' element={<Main />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
