import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isAuth: false,
	userName: "",
	isLecturer: false,
	zoomUserId: "",
	meetingList: [],
	socketId: "",
	mailAdress: "",
};

export const auth = createSlice({
	name: "auth",
	initialState,
	reducers: {
		logOut: () => initialState,
		logIn: (state, action) => ({
			...state,
			isAuth: action.payload.isAuth,
			userName: action.payload.userName,
			isLecturer: action.payload.isLecturer,
			zoomUserId: action.payload.zoomUserId,
			meetingList: action.payload.meetingList,
			mailAdress: action.payload.mailAdress,
		}),
		toggleIsLecturer: (state, action) => ({
			...state,
			isLecturer: action.payload,
		}),
		setMeetings: (state, action) => ({
			...state,
			meetingList: action.payload,
		}),
		setSocketId: (state, action) => {
			return { ...state, socketId: action.payload };
		},
	},
});

export const { logIn, logOut, toggleIsLecturer, setMeetings, setSocketId } =
	auth.actions;
export default auth.reducer;
