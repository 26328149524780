import React from "react";
import "./style.scss";

function Button({ children, type, clickFunc, customClass }) {
	return (
		<div
			className={`button-class ${type ? type : ""} ${
				customClass ? customClass : ""
			}`}
			onClick={clickFunc}>
			{children}
		</div>
	);
}

export default Button;
